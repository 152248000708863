import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import addDays from 'date-fns/addDays';
import { TextField, FormField, Button, DatePicker } from '../../components/Elements';
import { titleCase } from '../../utils';
import { Dialog } from '../../components/Dialog';

const Section = styled.section`
  padding: 4rem 0 8rem;

  .container {
    max-width: 37.5rem;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .time-and-date-container {
    display: flex;
  }

  .time-and-date__date-picker {
    margin-right: 1rem;
  }

  .time-selector {
    position: relative;
  }

  .time-selector-error {
    color: ${({ theme }) => theme.errorColor};
    position: absolute;
    bottom: -1.5rem;
    left: 0.25rem;
    white-space: nowrap;
  }

  .checkbox-message {
    position: absolute;
    bottom: -1rem;
    left: 2rem;
    color: ${({ theme }) => theme.errorColor};
  }

  .checkbox-font-color {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .manager-message {
    margin-bottom: 4rem;
    padding: 1rem;
    background-color: ${({ theme }) => theme.lowEmphasisBackgroundColor};
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.outlinePrimaryColor};
    color: ${({ theme }) => theme.onLightColorMediumEmphasis};
  }

  @media (min-width: 40rem) {
    .manager-message {
      padding: 1rem 2rem;
    }
  }
`;

const Section01 = () => {
  //
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isFailDialogOpen, setIsFailDialogOpen] = useState(false);
  const [bookingTimes, setBookingTimes] = useState([]);
  const [bookerName, setBookerName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
  }, []);

  const minDate = addDays(new Date(), 1);

  const { register, handleSubmit, watch, errors, control, getValues, reset, setValue } = useForm({
    defaultValues: {
      date: minDate,
    },
  });

  useEffect(() => {
    const { date } = getValues();

    if (date) {
      //
      const dayOfWeek = date.getDay();

      setValue('time', 'default');

      const block1 = [
        '11:30 am',
        '11:45 am',
        '12:00 pm',
        '12:15 pm',
        '12:30 pm',
        '12:45 pm',
        '1:00 pm',
        '1:15 pm',
        '1:30 pm',
        '1:45 pm',
        '2:00 pm',
        '5:30 pm',
        '5:45 pm',
        '6:00 pm',
        '6:15 pm',
        '6:30 pm',
        '6:45 pm',
        '7:00 pm',
        '7:15 pm',
        '7:30 pm',
        '7:45 pm',
        '8:00 pm',
        '8:15 pm',
        '8:30 pm',
        '8:45 pm',
        '9:00 pm',
        '9:15 pm',
        '9:30 pm',
        '9:45 pm',
        '10:00 pm',
        '10:15 pm',
        '10:30 pm',
      ];

      const block2 = [
        '5:30 pm',
        '5:45 pm',
        '6:00 pm',
        '6:15 pm',
        '6:30 pm',
        '6:45 pm',
        '7:00 pm',
        '7:15 pm',
        '7:30 pm',
        '7:45 pm',
        '8:00 pm',
        '8:15 pm',
        '8:30 pm',
        '8:45 pm',
        '9:00 pm',
        '9:15 pm',
        '9:30 pm',
        '9:45 pm',
        '10:00 pm',
        '10:15 pm',
        '10:30 pm',
      ];
      if (dayOfWeek === 0) {
        setBookingTimes([...block2]);
      } else {
        setBookingTimes([...block1]);
      }
    }
  }, [watch('date')]);

  const submitForm = async () => {
    const { name, email, pax, phone, date, time } = getValues();
    const formattedName = titleCase(name);
    const formattedEmail = email.toLowerCase();
    const formattedDate = new Date(date).toLocaleDateString('en-SG');

    try {
      setBookerName(formattedName);
      setIsSubmitting(true);
      const res = await fetch('https://zahqla6no4.execute-api.ap-southeast-1.amazonaws.com/prod/booking', {
        method: 'POST',
        body: JSON.stringify({
          name: formattedName,
          email: formattedEmail,
          phone,
          pax,
          date: formattedDate,
          time,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (res.status === 200) {
        setIsSuccessDialogOpen(true);
        setIsSubmitting(false);
        reset();
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsFailDialogOpen(true);
    }
  };

  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    window.submitValidatedForm = (token) => {
      window.grecaptcha.reset();
      if (token) {
        submitForm();
      }
    };
  }

  return (
    <>
      <Section>
        <div className="container">
          <div className="manager-message roboto-text-base roboto-text--body">
            <p>Welcome,</p>
            <p>
              Kindly note this is booking request page. Submitting a request is not a confirmation of your booking. Our
              team will contact / revert to you to confirm the table.
            </p>
            <p>Thanks.</p>
          </div>
          <form onSubmit={handleSubmit(() => window.grecaptcha.execute())}>
            <FormField>
              <TextField
                name="name"
                formRef={register({ required: 'Your name is required' })}
                isFilled={Boolean(watch('name'))}
                label="Name"
                helpertext="Required *"
                error={
                  errors.name && <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.name.message}</span>
                }
                modifiers={['filled']}
              />
            </FormField>
            <FormField>
              <TextField
                name="email"
                formRef={register({
                  required: 'Your email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please provide a valid email address',
                  },
                })}
                isFilled={Boolean(watch('email'))}
                label="Email"
                helpertext="Required *"
                error={
                  errors.email && (
                    <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.email.message}</span>
                  )
                }
                modifiers={['filled']}
              />
            </FormField>
            <FormField>
              <TextField
                name="pax"
                formRef={register({
                  required: 'Number of diners is required',
                })}
                isFilled={Boolean(watch('pax'))}
                label="Number of diners"
                helpertext="Required *"
                error={
                  errors.pax && <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.pax.message}</span>
                }
                modifiers={['filled']}
                type="number"
              />
            </FormField>
            <FormField>
              <TextField
                type="tel"
                name="phone"
                formRef={register({
                  minLength: { value: 8, message: 'At least 8 digits' },
                  maxLength: { value: 12, message: 'No more than 12 digits' },
                  required: 'Your phone numnber is required',
                  pattern: { value: /^-?[0-9]\d*\.?\d*$/i, message: 'Please provide a valid phone number' },
                })}
                isFilled={Boolean(watch('phone'))}
                label="Phone"
                error={
                  errors.phone && (
                    <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.phone.message}</span>
                  )
                }
                modifiers={['filled']}
              />
            </FormField>
            <FormField>
              <div className="time-and-date-container">
                <div className="time-and-date__date-picker">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: 'Booking Date is required' }}
                      render={({ onChange, value }) => (
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={value}
                          minDate={minDate}
                          disablePast
                          onChange={onChange}
                          autoOk
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          invalidDateMessage="Please enter a valid date"
                          shouldDisableDate={(date) => date.getTime() === new Date('2024-07-26T00:00').getTime() || date.getTime() === new Date('2024-08-09T00:00').getTime()}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.date && (
                    <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.date.message}</span>
                  )}
                </div>
                <div className="time-selector">
                  <Controller
                    as={
                      <Select variant="outlined">
                        <MenuItem value="default">Choose a time</MenuItem>
                        {bookingTimes.map((time) => {
                          return (
                            <MenuItem value={time} key={time}>
                              {time}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="time"
                    control={control}
                    defaultValue="default"
                    rules={{
                      validate: () => {
                        return getValues('time') !== 'default';
                      },
                    }}
                  />
                  {errors.time && (
                    <div className="roboto-text-sm roboto-text-sm--subtitle time-selector-error">
                      Please choose a time
                    </div>
                  )}
                </div>
              </div>
            </FormField>

            <FormField>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agree"
                    inputRef={register({
                      required: (
                        <div className="roboto-text-sm roboto-text-sm--subtitle checkbox-message">
                          You must agree to our policy
                        </div>
                      ),
                    })}
                  />
                }
                label={
                  <>
                    <p className="roboto-text-base roboto-text-base--subtitle checkbox-font-color">
                      Agree to our{' '}
                      <a
                        className="link"
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="label"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </>
                }
              />
              {errors.agree && <span>{errors.agree.message}</span>}
            </FormField>

            <div
              className="g-recaptcha visually-hidden"
              data-sitekey={process.env.GATSBY_ReCAPTCHA_SITEKEY}
              data-callback="submitValidatedForm"
              data-size="invisible"
              data-formfield="captcha"
              data-badge="inline"
            />

            <Button type="submit" modifiers={['contained', 'full']} disabled={isSubmitting}>
              {isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </form>
        </div>
      </Section>
      <Dialog
        open={isSuccessDialogOpen}
        dialogTitle={`Dear ${bookerName}`}
        dialogMessage={
          <>
            Thank you for your booking request.
            <p>
              This is not aconfirmation of booking, but only acknowledgement we have received your request. Our team
              will check availability and revert to you soon separately.
            </p>
            <p>
              In the meantime, if you need any further assistance, contact us via{' '}
              <a href="mailto:manager@tandoorizaika.sg ">email</a> or call <a href="tel:+6565353440">+65-6535-3440</a>{' '}
              during open hours.
              <br />
              <br />
              Thank you.
            </p>
            <p>
              Manager
              <br />
              Tandoori Zaika
            </p>
          </>
        }
        dialogPrimaryActionTitle="Close"
        dialogPrimaryAction={() => setIsSuccessDialogOpen(false)}
        closeFunc={() => setIsSuccessDialogOpen(false)}
      />
      <Dialog
        open={isFailDialogOpen}
        dialogTitle="Something has gone wrong."
        dialogMessage="Please resubmit your booking."
        dialogPrimaryActionTitle="Close"
        dialogPrimaryAction={() => setIsFailDialogOpen(false)}
        closeFunc={() => setIsFailDialogOpen(false)}
      />
    </>
  );
};

export default Section01;
